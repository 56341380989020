import React from 'react';
import { NavLink } from 'react-router-dom';
import TrainingService from '../../Services/training.service';
import UpdateTrainingDataModal from './CU/UpdateTrainingDataModal';

export default function Training({ training }) {
  const DeleteTraining = () => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const token = currentUser?.token;

    TrainingService.DeleteTraining(training?.id, token)
      .then((response) => {
        window.location.reload(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <tr>
      <th scope="row">#00{training.id}</th>
      <td>
        {' '}
        <img
          src={process.env.PUBLIC_URL + `/assets/images/${training.Image}`}
          style={{ width: '100px', height: '50px', marginRight: '10px' }}
        />
      </td>
      <td>{training.Title}</td>
      <td>{training.TrainingType}</td>
      <td>{training.Establishment}</td>
      <td>{training.Price}</td>
      <td>{training.StartingDate}</td>
      <td>{training.EndingDate}</td>
      <td>
        <button
          style={{ marginRight: '10%' }}
          type="button"
          onClick={DeleteTraining}
          className="btn btn-danger btn-sm"
        >
          <i class="fa-solid fa-trash"></i>
        </button>
        <button
          style={{ marginRight: '10%' }}
          type="button"
          className="btn btn-warning btn-sm"
          data-bs-toggle="modal"
          data-bs-target={`#UpdateTrainingDataModal-${training.id}`}
        >
          <i class="fa-solid fa-pen-to-square"></i>
        </button>
        <UpdateTrainingDataModal training={training} />
        <NavLink
          to="/Playlist"
          state={{ training }}
          type="button"
          className="btn btn-success btn-sm"
        >
          <i class="fa-solid fa-eye"></i>
        </NavLink>
      </td>
    </tr>
  );
}
