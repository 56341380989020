import React, { useState, useEffect } from 'react';
import LoginModal from './LoginModal';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import TrainingService from '../../Services/training.service';

export default function Home() {
  document.title = 'Home';
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();

  const GoToRegister = () => {
    navigate('/Register');
  };

  const [trainings, setTrainings] = useState([]);

  const retrieveAll4Trainings = () => {
    TrainingService.GetAll4Trainings()
      .then((response) => {
        setTrainings(response.data.trainings);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    retrieveAll4Trainings();
  }, []);

  const Training = ({ training }) => {
    return (
      <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6">
        <div className="product_grid card" style={{ borderRadius: '10px' }}>
          <div className="card-body gray">
            <NavLink to="/TrainingDetail" state={{ training }}>
              <div className="shop_thumb py-5 px-3 product-slide">
                <div className="single-swipper">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      `/assets/images/${training.Image}`
                    }
                    className="img-fluid"
                    style={{
                      width: '100%',
                      height: '160px',
                      objectFit: 'cover',
                      borderRadius: '3px',
                    }}
                    alt=""
                  />
                </div>
                <h5
                  style={{ marginTop: '15px' }}
                  className="ft--medium fs-6 lh-base mb-0"
                >
                  {training.Title}
                </h5>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    );
  };

  const All4Trainings = trainings.map((training, index) => (
    <Training key={index} training={training} />
  ));

  return (
    <div id="main-wrapper">
      <div className="clearfix" />

      {/* ============================ Hero Banner  Start================================== */}
      <div className="image-cover hero-header bg-dark" data-overlay={0}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12">
              <div className="elcoss-excort mt-xl-5 mt-lg-4 mt-md-4 wow animated fadeInLeft">
                <h1 className="mb-4">
                  Learn and thrive, your success starts here
                  <br />
                  {/* With <span className="text-info">Killar.Com</span> */}
                </h1>
                <p className="fs-5 fs-mob">
                  Explore our top-rated development courses. Skill up fast with
                  features like coding exercises and practice tests.
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7 col-md-12 col-sm-12">
              {currentUser == null ? (
                <div className="search-from-clasic mt-5 mb-3  wow animated fadeInLeft">
                  <div className="hero-search-content">
                    <div className="row">
                      <div className="form-group">
                        <button
                          onClick={GoToRegister}
                          type="submit"
                          className="btn btn-info full-width"
                        >
                          Signup Free
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="features-groupss mt-4 mb-2">
                <ul className="row gx-3 gy-3 p-0 wow animated fadeInLeft">
                  <li className="font--medium text-light col-xl-6 col-lg-6 col-6">
                    <i className="fa-solid fa-check text-success me-2" />
                    Popular Programs
                  </li>
                  <li className="font--medium text-light col-xl-6 col-lg-6 col-6">
                    <i className="fa-solid fa-check text-success me-2" />
                    Popular Skills
                  </li>
                  <li className="font--medium text-light col-xl-6 col-lg-6 col-6">
                    <i className="fa-solid fa-check text-success me-2" />
                    Popular Careers
                  </li>
                  <li className="font--medium text-light col-xl-6 col-lg-6 col-6">
                    <i className="fa-solid fa-check text-success me-2" />
                    Popular AI Content
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="position-absolute bottom-0 end-0 z-0 d-lg-block d-none wow animated fadeInRight">
          <img src="assets/img/custom-img/cr-2.png" alt="SVG" width={600} />
        </div>
      </div>
      {/* ============================ Hero Banner End ================================== */}

      <section>
        <div className="container">
          {/* <div className="row align-items-center justify-content-center">
            <div className="col-xl-7 col-lg-7 col-md-11 mb-3">
              <div className="sec-heading text-center wow animated fadeInUp">
                <div className="label text-info bg-light-info d-inline-flex rounded-4 mb-2 font--medium">
                  <span>Loved Our Features</span>
                </div>
                <h2 className="mb-1">Why You'll Love Us?</h2>
                <p className="test-muted fs-6">
                  At vero eos et accusamus et iusto odio dignissimos ducimus
                </p>
              </div>
            </div>
          </div> */}
          <div className="row justify-content-between align-items-center mb-3">
            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12">
              <div className="position-relative exloi wow animated fadeInLeft">
                <div className="exloi-caption">
                  {/* <div className="label text-success bg-light-success d-inline-flex rounded-4 mb-2 font--medium">
                    <span>Overview</span>
                  </div> */}
                  <h2 className="lh-base mb-3">
                    Take the next step toward your personal and professional
                    goals
                    <br />
                    with AGR.
                  </h2>
                  <p className="mb-0 fs-5 fw-light">
                    Receive personalized recommendations from the full AGR
                    catalog.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="position-relative exloi mt-md-0 mt-4 wow animated fadeInRight">
                <div className="position-relative ps-lg-4">
                  <img
                    src="assets/img/work-1.jpg"
                    className="img-fluid rounded-4"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-between align-items-center mt-5">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="position-relative exloi wow animated fadeInLeft">
                <div className="position-relative pe-4">
                  <img
                    src="assets/img/side-7.png"
                    className="img-fluid rounded-3"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12">
              <div className="position-relative exloi mt-md-0 mt-4 wow animated fadeInRight">
                <div className="exloi-caption">
                  {/* <div className="label text-success bg-light-success d-inline-flex rounded-4 mb-2 font--medium">
                    <span>Our Best Features</span>
                  </div> */}
                  <h2 className="lh-base mb-3">
                    We Work Hard To let you
                    <br />
                    make progress toward a brilliant future
                  </h2>
                  <p className="mb-0 fw-light fs-5">
                    Unlock your potential with our comprehensive support and
                    resources.
                  </p>
                </div>
                {/* <div className="exloi-blocks mt-5">
                  <div className="row g-4">
                    <div className="col">
                      <div className="exloi-block py-4 px-3 rounded-3 text-center bg-light-info">
                        <div className="exloi-78 fs-1 text-info">
                          <i className="fa-solid fa-lock" />
                        </div>
                        <div className="exloi-y87">
                          <h6 className="mb-0 font--bold">Pay Securely</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="exloi-block py-4 px-3 rounded-3 text-center bg-light-info">
                        <div className="exloi-78 fs-1 text-info">
                          <i className="fa-solid fa-gauge" />
                        </div>
                        <div className="exloi-y87">
                          <h6 className="mb-0 font--bold">Control Power</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="exloi-block py-4 px-3 rounded-3 text-center bg-light-info">
                        <div className="exloi-78 fs-1 text-info">
                          <i className="fa-solid fa-phone-volume" />
                        </div>
                        <div className="exloi-y87">
                          <h6 className="mb-0 font--bold">Eco Support</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="clearfix" />

      {/* ============================  Featurs End ================================== */}

      <>
        <section className="gray-simple">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-7 col-lg-7 col-md-11 mb-3">
                <div className="sec-heading text-center wow animated fadeInUp">
                  <div className="label text-success bg-light-success d-inline-flex rounded-4 mb-2 font--medium">
                    <span>Loved Our Features</span>
                  </div>
                  <h2 className="mb-1">Over 350+ Integration</h2>
                  {/* <p className="test-muted fs-6">
                    At vero eos et accusamus dignissimos ducimus
                  </p> */}
                </div>
              </div>
            </div>
            <div className="row justify-content-center row-cols-3 row-cols-md-5 row-cols-lg-6 g-md-4 g-3">
              <div className="col">
                <div className="card border-0 rounded-4 px-4 py-4 wow animated fadeInUp">
                  <div className="position-relative text-center">
                    <img
                      src="assets/img/brand/ensia-new-logo-1-e1677764094479.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card border-0 rounded-4 px-4 py-4 wow animated fadeInUp">
                  <div className="position-relative text-center">
                    <img
                      src="assets/img/brand/logo_asf_2_small3.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card border-0 rounded-4 px-4 py-4 wow animated fadeInUp">
                  <div className="position-relative text-center">
                    <img
                      src="assets/img/brand/logo_min.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card border-0 rounded-4 px-4 py-4 wow animated fadeInUp">
                  <div className="position-relative text-center">
                    <img
                      src="assets/img/brand/images (1).png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card border-0 rounded-4 px-4 py-4 wow animated fadeInUp">
                  <div className="position-relative text-center">
                    <img
                      src="assets/img/brand/images (2).png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card border-0 rounded-4 px-4 py-4 wow animated fadeInUp">
                  <div className="position-relative text-center">
                    <img
                      src="assets/img/brand/images.jfif"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card border-0 rounded-4 px-4 py-4 wow animated fadeInUp">
                  <div className="position-relative text-center">
                    <img
                      src="assets/img/brand/images.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card border-0 rounded-4 px-4 py-4 wow animated fadeInUp">
                  <div className="position-relative text-center">
                    <img
                      src="assets/img/brand/logo-esgee-png-1.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card border-0 rounded-4 px-4 py-4 wow animated fadeInUp">
                  <div className="position-relative text-center">
                    <img
                      src="assets/img/brand/images (3).png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              {/* <div className="col">
                <div className="card border-0 rounded-4 px-4 py-4 wow animated fadeInUp">
                  <div className="position-relative text-center">
                    <img
                      src="assets/img/brand/logo-10.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div> */}
              {/* <div className="col">
                <div className="card border-0 rounded-4 px-4 py-4 wow animated fadeInUp">
                  <div className="position-relative text-center">
                    <img
                      src="assets/img/brand/logo-11.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div> */}
              {/* <div className="col">
                <div className="card border-0 rounded-4 px-4 py-4 wow animated fadeInUp">
                  <div className="position-relative text-center">
                    <img
                      src="assets/img/brand/logo-12.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        <div className="clearfix" />
      </>

      {/* ============================ Integration Apps End ================================== */}
      <section>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-xl-7 col-lg-7 col-md-11 mb-3">
              <div className="sec-heading text-center">
                {/* <div className="label text-success bg-light-success d-inline-flex rounded-4 mb-2 font--medium">
                  <span>Our Services</span>
                </div> */}
                <h2 className="mb-1">
                  Empower Your Journey: Training Grounds Across Multiple
                  Domains.
                </h2>
                {/* <p className="test-muted fs-6">
                  At vero eos et accusamus et iusto odio dignissimos ducimus
                </p> */}
              </div>
            </div>
          </div>
          <div className="row g-4">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="card position-relative rounded-4 border p-4 wow animated fadeInLeft">
                <div className="position-absolute top-0 end-0 mt-3 me-3">
                  <span className="badge badge-primary">New</span>
                </div>
                <div className="d-md-flex align-items-start">
                  <div className="advj-icons flex-shrink-0 mb-md-0 mb-2">
                    <div className="position-absolute w-13 h-13 circle bg-light-info top-0 start-0 mt-3 ms-3" />
                    <span className="svg-icon text-primary svg-icon-2hx">
                      <svg
                        width={40}
                        height={40}
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M8.9 21L7.19999 22.6999C6.79999 23.0999 6.2 23.0999 5.8 22.6999L4.1 21H8.9ZM4 16.0999L2.3 17.8C1.9 18.2 1.9 18.7999 2.3 19.1999L4 20.9V16.0999ZM19.3 9.1999L15.8 5.6999C15.4 5.2999 14.8 5.2999 14.4 5.6999L9 11.0999V21L19.3 10.6999C19.7 10.2999 19.7 9.5999 19.3 9.1999Z"
                          fill="currentColor"
                        />
                        <path
                          d="M21 15V20C21 20.6 20.6 21 20 21H11.8L18.8 14H20C20.6 14 21 14.4 21 15ZM10 21V4C10 3.4 9.6 3 9 3H4C3.4 3 3 3.4 3 4V21C3 21.6 3.4 22 4 22H9C9.6 22 10 21.6 10 21ZM7.5 18.5C7.5 19.1 7.1 19.5 6.5 19.5C5.9 19.5 5.5 19.1 5.5 18.5C5.5 17.9 5.9 17.5 6.5 17.5C7.1 17.5 7.5 17.9 7.5 18.5Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="advj ps-md-3 ps-0">
                    <h5 className="lh-base">UX/UI Experience</h5>
                    <p className="fs-6 lh-base m-0">
                      Using test items of real content and data in designs will
                      help, but there's no guarantee that every oddity will be
                      found and corrected. Then a prototype or beta site with
                      real content.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="card position-relative rounded-4 border p-4 wow animated fadeInRight">
                <div className="d-md-flex align-items-start">
                  <div className="advj-icons flex-shrink-0 mb-md-0 mb-2">
                    <div className="position-absolute w-13 h-13 circle bg-light-info top-0 start-0 mt-3 ms-3" />
                    <span className="svg-icon text-primary svg-icon-2hx">
                      <svg
                        width={40}
                        height={40}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.95 18.9688C16.75 18.9688 16.55 18.8688 16.35 18.7688C15.85 18.4688 15.75 17.8688 16.05 17.3688L19.65 11.9688L16.05 6.56876C15.75 6.06876 15.85 5.46873 16.35 5.16873C16.85 4.86873 17.45 4.96878 17.75 5.46878L21.75 11.4688C21.95 11.7688 21.95 12.2688 21.75 12.5688L17.75 18.5688C17.55 18.7688 17.25 18.9688 16.95 18.9688ZM7.55001 18.7688C8.05001 18.4688 8.15 17.8688 7.85 17.3688L4.25001 11.9688L7.85 6.56876C8.15 6.06876 8.05001 5.46873 7.55001 5.16873C7.05001 4.86873 6.45 4.96878 6.15 5.46878L2.15 11.4688C1.95 11.7688 1.95 12.2688 2.15 12.5688L6.15 18.5688C6.35 18.8688 6.65 18.9688 6.95 18.9688C7.15 18.9688 7.35001 18.8688 7.55001 18.7688Z"
                          fill="currentColor"
                        />
                        <path
                          opacity="0.3"
                          d="M10.45 18.9687C10.35 18.9687 10.25 18.9687 10.25 18.9687C9.75 18.8687 9.35 18.2688 9.55 17.7688L12.55 5.76878C12.65 5.26878 13.25 4.8687 13.75 5.0687C14.25 5.1687 14.65 5.76878 14.45 6.26878L11.45 18.2688C11.35 18.6688 10.85 18.9687 10.45 18.9687Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="advj ps-md-3 ps-0">
                    <h5 className="lh-base">Web Development</h5>
                    <p className="fs-6 lh-base m-0">
                      Using test items of real content and data in designs will
                      help, but there's no guarantee that every oddity will be
                      found and corrected. Then a prototype or beta site with
                      real content.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="card position-relative rounded-4 border p-4 wow animated fadeInLeft">
                <div className="d-md-flex align-items-start">
                  <div className="advj-icons flex-shrink-0 mb-md-0 mb-2">
                    <div className="position-absolute w-13 h-13 circle bg-light-info top-0 start-0 mt-3 ms-3" />
                    <span className="svg-icon text-primary svg-icon-2hx">
                      <svg
                        width={40}
                        height={40}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11 15.9C9.5 13 6.5 11 3 11C3 15.6 6.5 19.4 11 19.9V15.9Z"
                          fill="currentColor"
                        />
                        <path
                          opacity="0.3"
                          d="M21 11C17.5 11 14.5 13 13 15.9V11C13 10.4 12.6 10 12 10C11.4 10 11 10.4 11 11V21C11 21.6 11.4 22 12 22C12.6 22 13 21.6 13 21V19.9C17.5 19.4 21 15.6 21 11Z"
                          fill="currentColor"
                        />
                        <path
                          opacity="0.3"
                          d="M12 9C13.933 9 15.5 7.433 15.5 5.5C15.5 3.567 13.933 2 12 2C10.067 2 8.5 3.567 8.5 5.5C8.5 7.433 10.067 9 12 9Z"
                          fill="currentColor"
                        />
                        <path
                          d="M14 11L12 12L10 11V8.40002H14V11Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="advj ps-md-3 ps-0">
                    <h5 className="lh-base">Branding Design</h5>
                    <p className="fs-6 lh-base m-0">
                      Using test items of real content and data in designs will
                      help, but there's no guarantee that every oddity will be
                      found and corrected. Then a prototype or beta site with
                      real content.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="card position-relative rounded-4 border p-4 wow animated fadeInRight">
                {/* <div className="position-absolute top-0 end-0 mt-3 me-3">
                  <span className="badge badge-success">Hot</span>
                </div> */}
                <div className="d-md-flex align-items-start">
                  <div className="advj-icons flex-shrink-0 mb-md-0 mb-2">
                    <div className="position-absolute w-13 h-13 circle bg-light-info top-0 start-0 mt-3 ms-3" />
                    <span className="svg-icon text-primary svg-icon-2hx">
                      <svg
                        width={40}
                        height={40}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M8 2V16H2V8.60001C2 8.20001 2.2 7.8 2.5 7.5L8 2ZM16 8V22L21.5 16.5C21.8 16.2 22 15.8 22 15.4V8H16Z"
                          fill="currentColor"
                        />
                        <path
                          d="M22 8H8V2H15.4C15.8 2 16.2 2.2 16.5 2.5L22 8ZM2 16L7.5 21.5C7.8 21.8 8.20001 22 8.60001 22H16V16H2Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="advj ps-md-3 ps-0">
                    <h5 className="lh-base">Digital Marketing</h5>
                    <p className="fs-6 lh-base m-0">
                      Using test items of real content and data in designs will
                      help, but there's no guarantee that every oddity will be
                      found and corrected. Then a prototype or beta site with
                      real content.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="card position-relative rounded-4 border p-4 wow animated fadeInLeft">
                {/* <div className="position-absolute top-0 end-0 mt-3 me-3">
                  <span className="badge badge-warning">Deal</span>
                </div> */}
                <div className="d-md-flex align-items-start">
                  <div className="advj-icons flex-shrink-0 mb-md-0 mb-2">
                    <div className="position-absolute w-13 h-13 circle bg-light-info top-0 start-0 mt-3 ms-3" />
                    <span className="svg-icon text-primary svg-icon-2hx">
                      <svg
                        width={40}
                        height={40}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M18 21.6C16.3 21.6 15 20.3 15 18.6V2.50001C15 2.20001 14.6 1.99996 14.3 2.19996L13 3.59999L11.7 2.3C11.3 1.9 10.7 1.9 10.3 2.3L9 3.59999L7.70001 2.3C7.30001 1.9 6.69999 1.9 6.29999 2.3L5 3.59999L3.70001 2.3C3.50001 2.1 3 2.20001 3 3.50001V18.6C3 20.3 4.3 21.6 6 21.6H18Z"
                          fill="currentColor"
                        />
                        <path
                          d="M12 12.6H11C10.4 12.6 10 12.2 10 11.6C10 11 10.4 10.6 11 10.6H12C12.6 10.6 13 11 13 11.6C13 12.2 12.6 12.6 12 12.6ZM9 11.6C9 11 8.6 10.6 8 10.6H6C5.4 10.6 5 11 5 11.6C5 12.2 5.4 12.6 6 12.6H8C8.6 12.6 9 12.2 9 11.6ZM9 7.59998C9 6.99998 8.6 6.59998 8 6.59998H6C5.4 6.59998 5 6.99998 5 7.59998C5 8.19998 5.4 8.59998 6 8.59998H8C8.6 8.59998 9 8.19998 9 7.59998ZM13 7.59998C13 6.99998 12.6 6.59998 12 6.59998H11C10.4 6.59998 10 6.99998 10 7.59998C10 8.19998 10.4 8.59998 11 8.59998H12C12.6 8.59998 13 8.19998 13 7.59998ZM13 15.6C13 15 12.6 14.6 12 14.6H10C9.4 14.6 9 15 9 15.6C9 16.2 9.4 16.6 10 16.6H12C12.6 16.6 13 16.2 13 15.6Z"
                          fill="currentColor"
                        />
                        <path
                          d="M15 18.6C15 20.3 16.3 21.6 18 21.6C19.7 21.6 21 20.3 21 18.6V12.5C21 12.2 20.6 12 20.3 12.2L19 13.6L17.7 12.3C17.3 11.9 16.7 11.9 16.3 12.3L15 13.6V18.6Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="advj ps-md-3 ps-0">
                    <h5 className="lh-base">Research &amp; Analytics</h5>
                    <p className="fs-6 lh-base m-0">
                      Using test items of real content and data in designs will
                      help, but there's no guarantee that every oddity will be
                      found and corrected. Then a prototype or beta site with
                      real content.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="card position-relative rounded-4 border p-4 wow animated fadeInRight">
                <div className="d-md-flex align-items-start">
                  <div className="advj-icons flex-shrink-0 mb-md-0 mb-2">
                    <div className="position-absolute w-13 h-13 circle bg-light-info top-0 start-0 mt-3 ms-3" />
                    <span className="svg-icon text-primary svg-icon-2hx">
                      <svg
                        width={40}
                        height={40}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M19.0003 4.40002C18.2003 3.50002 17.1003 3 15.8003 3C14.1003 3 12.5003 3.99998 11.8003 5.59998C11.0003 7.39998 11.9004 9.49993 11.2004 11.2999C10.1004 14.2999 7.80034 16.9 4.90034 17.9C4.50034 18 3.80035 18.2 3.10035 18.2C2.60035 18.3 2.40034 19 2.90034 19.2C4.40034 19.8 6.00033 20.2 7.80033 20.2C15.8003 20.2 20.2004 13.5999 20.2004 7.79993C20.2004 7.59993 20.2004 7.39995 20.2004 7.19995C20.8004 6.69995 21.4003 6.09993 21.9003 5.29993C22.2003 4.79993 21.9003 4.19998 21.4003 4.09998C20.5003 4.19998 19.7003 4.20002 19.0003 4.40002Z"
                          fill="currentColor"
                        />
                        <path
                          d="M11.5004 8.29997C8.30036 8.09997 5.60034 6.80004 3.30034 4.50004C2.90034 4.10004 2.30037 4.29997 2.20037 4.79997C1.60037 6.59997 2.40035 8.40002 3.90035 9.60002C3.50035 9.60002 3.10037 9.50007 2.70037 9.40007C2.40037 9.30007 2.00036 9.60004 2.10036 10C2.50036 11.8 3.60035 12.9001 5.40035 13.4001C5.10035 13.5001 4.70034 13.5 4.30034 13.6C3.90034 13.6 3.70035 14.1001 3.90035 14.4001C4.70035 15.7001 5.90036 16.5 7.50036 16.5C8.80036 16.5 10.1004 16.5 11.2004 15.8C12.7004 14.9 13.9003 13.2001 13.8003 11.4001C13.9003 10.0001 13.1004 8.39997 11.5004 8.29997Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="advj ps-md-3 ps-0">
                    <h5 className="lh-base">Social Media Marketing</h5>
                    <p className="fs-6 lh-base m-0">
                      Using test items of real content and data in designs will
                      help, but there's no guarantee that every oddity will be
                      found and corrected. Then a prototype or beta site with
                      real content.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ Advance Services Start ================================== */}
      <section className="position-relative gray">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-xl-7 col-lg-7 col-md-11 mb-3">
              <div className="sec-heading text-center wow animated fadeInUp">
                <div className="label text-success bg-light-success d-inline-flex rounded-4 mb-2 font--medium">
                  <span>Latest Updates</span>
                </div>
                <h2 className="mb-1">Learners also view</h2>
                {/* <p className="test-muted fs-6">
                  vero accusamus et iusto odio dignissimos ducimus
                </p> */}
              </div>
            </div>
          </div>
          {/* here to put the training map */}

          <div className="row align-items-start g-4">{All4Trainings}</div>
        </div>
      </section>
      {/* ============================ Latest Articles End ================================== */}
      {/* <section className="bg-cover call-action-container bg-info position-relative">
        <div className="position-absolute top-0 end-0 z-0">
          <img src="assets/img/alert-bg.png" alt="SVG" width={300} />
        </div>
        <div className="position-absolute bottom-0 start-0 me-10 z-0">
          <img src="assets/img/circle.png" alt="SVG" width={150} />
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-10 col-md-12 col-sm-12">
              <div className="call-action-wrap wow animated fadeInUp">
                <div className="call-action-caption">
                  <h2 className="text-light">
                    Are You Already Working With Us?
                  </h2>
                  <p className="text-light">
                    voluptatum deleniti atque corrupti quos dolores et quas
                    molestias.
                  </p>
                </div>
                <div className="call-action-form">
                  <form>
                    <div className="newsltr-form rounded-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Your email"
                      />
                      <button type="button" className="btn btn-dark">
                        Subscribe
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* ============================ Call To Action End ================================== */}
      {/* Log In Modal */}
      <LoginModal />
      {/* End Modal */}
      {/* Newsletter */}

      <a id="back2Top" className="top-scroll" title="Back to top" href="#">
        <i className="ti-arrow-up" />
      </a>
    </div>
  );
}
