import React, { useState } from "react";
import UserService from "../../../Services/user.service";

export default function LoginModal() {
  const [user, setUser] = useState({ email: "", password: "" });
  const [alert, setAlert] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value || null });
  };

  const Alert = () => {
    return (
      <div
        style={{ marginTop: "15px" }}
        class="alert alert-danger"
        role="alert"
      >
        Login failed. Please check your credentials and try again.
      </div>
    );
  };

  const handleLogin = () => {
    UserService.Login(user.email, user.password)
      .then((response) => {
        window.location.reload(false);
      })
      .catch((error) => {
        setAlert(true);
      });
  };

  return (
    <div
      className="modal auto-off fade"
      id="login"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="loginmodal"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered login-pop-form"
        role="document"
      >
        <div className="modal-content" id="loginmodal">
          <span
            className="mod-close"
            data-bs-dismiss="modal"
            aria-hidden="true"
          >
            <i className="fas fa-close" />
          </span>
          <div className="modal-header">
            {/* <div className="mdl-thumb">
              <img
                src="assets/img/ico.png"
                className="img-fluid"
                width={70}
                alt=""
              />
            </div> */}
            <div className="mdl-title">
              <h4 className="modal-header-title">Hello, Again</h4>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-login-form">
              <div className="form-floating mb-4">
                <input
                  type="email"
                  className="form-control"
                  placeholder="name@example.com"
                  name="email"
                  value={user.email}
                  onChange={handleInputChange}
                />
                <label>Email</label>
              </div>
              <div className="form-floating mb-4">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  name="password"
                  value={user.password}
                  onChange={handleInputChange}
                />
                <label>Password</label>
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-primary full-width font--bold btn-lg"
                  onClick={handleLogin}
                >
                  Log In
                </button>
                {alert ? <Alert /> : <></>}{" "}
              </div>
            </div>
          </div>
          {/* <div className="modal-footer">
            <p>
              Don't have an account yet?
              <a href="signup.html" className="theme-cl font--bold ms-1">
                Sign Up
              </a>
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
}
