import React, { useState } from 'react';
import TrainingService from '../../../Services/training.service';

export default function UpdateTrainingDataModal({ training }) {
  const [newTraining, setNewTraining] = useState({
    Title: training.Title,
    Description: training.Description,
    TrainingType: training.TrainingType,
    Establishment: training.Establishment,
    Price: training.Price,
    StartingDate: training.StartingDate,
    EndingDate: training.EndingDate,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewTraining({ ...newTraining, [name]: value || null });
  };

  const UpdateTraining = () => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const Token = currentUser?.token;
    TrainingService.UpdateTrainingData(newTraining, Token, training.id)
      .then((response) => {
        window.location.reload(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div
      className="modal auto-off fade"
      id={`UpdateTrainingDataModal-${training.id}`}
      tabIndex={-1}
      role="dialog"
      aria-labelledby={`exampleModalLabel-${training.id}`}
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered login-pop-form"
        role="document"
      >
        <div className="modal-content" id="loginmodal">
          <span
            className="mod-close"
            data-bs-dismiss="modal"
            aria-hidden="true"
          >
            <i className="fas fa-close" />
          </span>
          <div className="modal-header">
            <div className="mdl-title">
              <h4 className="modal-header-title">Update training</h4>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-login-form">
              <div className="form-floating mb-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Title"
                  name="Title"
                  value={newTraining.Title}
                  onChange={handleInputChange}
                />
                <label>Title</label>
              </div>
              <div className="form-floating mb-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Establishment"
                  name="Establishment"
                  value={newTraining.Establishment}
                  onChange={handleInputChange}
                />
                <label>Establishment</label>
              </div>
              <div className="form-floating mb-4">
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="Description"
                  name="Description"
                  value={newTraining.Description}
                  onChange={handleInputChange}
                />
                <label>Description</label>
              </div>
              <div className="form-floating mb-4">
                <select
                  className="form-select"
                  aria-label="Default"
                  name="TrainingType"
                  value={newTraining.TrainingType}
                  onChange={handleInputChange}
                >
                  <option value="AGR 1% : Financial Freedom">
                    AGR 1% : Financial Freedom
                  </option>
                  <option value="Speed-AGR Learn">Speed-AGR Learn</option>
                  <option value="Expertise-AGR Formation">
                    Expertise-AGR Formation
                  </option>
                </select>
                <label>Training type</label>
              </div>
              <div className="form-floating mb-4">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Price"
                  name="Price"
                  value={newTraining.Price}
                  onChange={handleInputChange}
                />
                <label>Price</label>
              </div>
              <div className="form-floating mb-4">
                <input
                  type="date"
                  className="form-control"
                  placeholder="Start date"
                  name="StartingDate"
                  value={newTraining.StartingDate}
                  onChange={handleInputChange}
                />
                <label>Start date</label>
              </div>
              <div className="form-floating mb-4">
                <input
                  type="date"
                  className="form-control"
                  placeholder="End date"
                  name="EndingDate"
                  value={newTraining.EndingDate}
                  onChange={handleInputChange}
                />
                <label>End date</label>
              </div>
              {newTraining.Title === training.Title &&
              newTraining.Description === training.Description &&
              newTraining.Price === training.Price &&
              newTraining.EndingDate === training.EndingDate &&
              newTraining.StartingDate === training.StartingDate &&
              newTraining.Establishment === training.Establishment &&
              newTraining.TrainingType === training.TrainingType ? (
                <div className="alert alert-primary" role="alert">
                  Nothing new changed to be saved !
                </div>
              ) : (
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-primary full-width font--bold btn-lg"
                    onClick={UpdateTraining}
                  >
                    Update
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
