import React, { useState, useEffect } from 'react';
import Training from './training';
import NewTrainingModal from './CU/NewTrainingModal';
import TrainingService from '../../Services/training.service';

export default function DashboardTrainings() {
  document.title = 'Trainings board';
  const [trainings, setTrainings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [trainingsPerPage] = useState(5);
  const [search, setSearch] = useState('');

  const retrieveAllTrainings = () => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const token = currentUser?.token;

    TrainingService.GetAllTrainingsForAdmin(token)
      .then((response) => {
        setTrainings(response.data.trainings);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    retrieveAllTrainings();
  }, []);

  const indexOfLastTraining = currentPage * trainingsPerPage;
  const indexOfFirstTraining = indexOfLastTraining - trainingsPerPage;
  const currentTrainings = trainings.slice(
    indexOfFirstTraining,
    indexOfLastTraining
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  const SearchedTraining = currentTrainings.filter((training) =>
    training.Title.toLowerCase().includes(search.toLowerCase())
  );

  const AllTrainings = SearchedTraining.map((training, index) => (
    <Training key={index} training={training} />
  ));

  return (
    <>
      <section className="bg-gray">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-12">
              <h1 className="pb-2 pb-sm-3">All trainings</h1>
            </div>

            <div className="col-xl-12 col-lg-9">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <div className="d-flex align-items-center flex-shrink-0">
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    data-bs-toggle="modal"
                    data-bs-target="#NewTrainingModal"
                  >
                    Add new training
                  </button>
                  <NewTrainingModal />
                </div>
              </div>
              {trainings?.length === 0 ? (
                <>
                  <section>
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="col-xl-12 col-lg-8 py-xl-5 py-4 text-center">
                          <h4 className="display-8 font--bold mb-4">
                            <i class="fa-solid fa-ban"></i> At the moment, you
                            don't have any training in the plateform.
                          </h4>
                        </div>
                      </div>
                    </div>
                  </section>
                  <div className="clearfix" />
                </>
              ) : (
                <>
                  <div className="col-xl-12 col-lg-12">
                    <div className="d-flex align-items-center justify-content-between mb-4">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Search for a training"
                        required=""
                        value={search}
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                  <div className="dash-wrapsw card border-0 rounded-4">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Ref Training</th>
                              <th scope="col">Cover picture</th>
                              <th scope="col">Title</th>
                              <th scope="col">Training type</th>
                              <th scope="col">Establishment</th>
                              <th scope="col">Price</th>
                              <th scope="col">Start date</th>
                              <th scope="col">End date</th>
                              <th scope="col">Actions</th>
                            </tr>
                          </thead>
                          <tbody>{AllTrainings}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <ul
          className="pagination justify-content-center"
          style={{ whiteSpace: 'nowrap' }}
        >
          {Array.from({
            length: Math.ceil(trainings.length / trainingsPerPage),
          }).map((_, index) => {
            const pageNumber = index + 1;
            const isCurrentPage = pageNumber === currentPage;

            return (
              <li
                key={index}
                className={`page-item ${isCurrentPage ? 'active' : ''}`}
                style={{ display: 'inline-block', margin: '0 2px' }}
              >
                <button
                  className="page-link"
                  onClick={() => paginate(pageNumber)}
                >
                  {pageNumber}
                </button>
              </li>
            );
          })}
        </ul>
      </section>

      <div className="clearfix" />
    </>
  );
}
