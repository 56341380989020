import React, { useState } from 'react';
import TrainingService from '../../../Services/training.service';

export default function NewTrainingModal() {
  const [training, setTraining] = useState({
    Title: '',
    Description: '',
    TrainingType: '',
    Establishment: '',
    Price: '',
    StartingDate: '',
    EndingDate: '',
    Token: '',
  });

  const [imageFile, setImageFile] = useState(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTraining({ ...training, [name]: value || null });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
  };

  const AddTraining = () => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const Token = currentUser?.token;

    const formData = new FormData();
    formData.append('Title', training.Title);
    formData.append('Description', training.Description);
    formData.append('TrainingType', training.TrainingType);
    formData.append('Establishment', training.Establishment);
    formData.append('Price', training.Price);
    formData.append('StartingDate', training.StartingDate);
    formData.append('EndingDate', training.EndingDate);
    formData.append('Token', Token);

    if (imageFile) {
      formData.append('file', imageFile);
    }

    TrainingService.CreateTraining(formData)
      .then((response) => {
        window.location.reload(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div
      className="modal auto-off fade"
      id="NewTrainingModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="NewTrainingModal"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered login-pop-form"
        role="document"
      >
        <div className="modal-content" id="loginmodal">
          <span
            className="mod-close"
            data-bs-dismiss="modal"
            aria-hidden="true"
          >
            <i className="fas fa-close" />
          </span>
          <div className="modal-header">
            <div className="mdl-title">
              <h4 className="modal-header-title">Add new training</h4>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-login-form">
              <div className="form-floating mb-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Title"
                  name="Title"
                  value={training.Title}
                  onChange={handleInputChange}
                />
                <label>Title</label>
              </div>
              <div className="form-floating mb-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Establishment"
                  name="Establishment"
                  value={training.Establishment}
                  onChange={handleInputChange}
                />
                <label>Establishment</label>
              </div>
              <div className="form-floating mb-4">
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="Description"
                  name="Description"
                  value={training.Description}
                  onChange={handleInputChange}
                />
                <label>Description</label>
              </div>
              <div className="form-floating mb-4">
                <select
                  className="form-select"
                  aria-label="Default"
                  name="TrainingType"
                  value={training.TrainingType}
                  onChange={handleInputChange}
                >
                  <option value="AGR 1% : Financial Freedom">
                    AGR 1% : Financial Freedom
                  </option>
                  <option value="Speed-AGR Learn">Speed-AGR Learn</option>
                  <option value="Expertise-AGR Formation">
                    Expertise-AGR Formation
                  </option>
                </select>
                <label>Training type</label>
              </div>
              <div className="form-floating mb-4">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Price"
                  name="Price"
                  value={training.Price}
                  onChange={handleInputChange}
                />
                <label>Price</label>
              </div>
              <div className="form-floating mb-4">
                <input
                  type="date"
                  className="form-control"
                  placeholder="Start date"
                  name="StartingDate"
                  value={training.StartingDate}
                  onChange={handleInputChange}
                />
                <label>Start date</label>
              </div>
              <div className="form-floating mb-4">
                <input
                  type="date"
                  className="form-control"
                  placeholder="End date"
                  name="EndingDate"
                  value={training.EndingDate}
                  onChange={handleInputChange}
                />
                <label>End date</label>
              </div>

              {/* New input for image upload */}
              <div className="form-floating mb-4">
                <input
                  type="file"
                  className="form-control"
                  name="Image"
                  accept="image/*"
                  onChange={handleImageChange}
                />
                <label style={{ marginBottom: '2%' }}>Training Image</label>
              </div>

              {!training.Title ||
              !training.Description ||
              !training.Price ||
              !training.EndingDate ||
              !training.StartingDate ||
              !training.TrainingType ||
              !training.Establishment ||
              !imageFile ? (
                <div className="alert alert-primary" role="alert">
                  Please fill in all fields.
                </div>
              ) : (
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-primary full-width font--bold btn-lg"
                    onClick={AddTraining}
                  >
                    Add
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
