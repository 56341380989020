import React, { useState } from "react";
import { Modal } from "antd";
import UserService from "../../Services/user.service";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
export default function Register() {
  document.title = "Sign Up";
  
  const [user, setUser] = useState({ email: "", password: "", login: "" });

  const [showAlert, setShowAlert] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value || null });
  };
  const navigate = useNavigate();

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleRegister = () => {
    if (
      !user.email ||
      !user.password ||
      !isValidEmail(user?.email) ||
      !user.login
    ) {
      setShowAlert(true);
      return;
    }
    UserService.Register(user.email, user.password, user.login)
      .then((response) => {
        Modal.success({
          content: "Your account has been created",
          onOk: () => {
            // Navigate to the root route ("/")
            navigate("/");
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const Alert = () => {
    return (
      <div
        style={{ marginTop: "15px" }}
        class="alert alert-danger"
        role="alert"
      >
        All fields need to be filled in correctly.
      </div>
    );
  };

  return (
    <div id="main-wrapper">
      <div className="d-lg-flex position-relative h-100">
        <NavLink
          className="circle bg-white text-primary border square--40 position-absolute top-0 end-0 mt-3 me-3"
          to="/Home"
          data-bs-toggle="tooltip"
          data-bs-placement="left"
          aria-label="Back to home"
          data-bs-original-title="Back to home"
        >
          <i className="fa-solid fa-house-user" />
        </NavLink>
        {/* Signup Form */}
        <div className="d-flex flex-column align-items-center w-50 h-100 px-4 px-md-5 py-5">
          <div className="w-100 mt-auto py-4 px-xl-4 px-lg-3">
            <div className="d-flex mb-3">
              <img
                src="assets/img/logo-copy.png"
                className="img-fluid"
                width={300}
                alt="logo"
              />
            </div>
            <h1 className="fs-1">Create An Account</h1>
            <p className="pb-3 mb-3 mb-lg-4">
              Welcome Back! Slect Methode to Register:
            </p>

            <div className="row row-cols-1">
              <div className="mb-4 position-relative">
                <input
                  className="form-control lg"
                  type="text"
                  placeholder="Your name"
                  required=""
                  name="login"
                  value={user.login}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-4 position-relative">
                <input
                  className={`form-control lg ${
                    !isValidEmail(user.email) ? "is-invalid" : ""
                  }`}
                  type="email"
                  placeholder="Email address"
                  required=""
                  name="email"
                  value={user.email}
                  onChange={handleInputChange}
                />
                {isValidEmail(user.email) || !showAlert ? null : (
                  <div className="invalid-feedback">Invalid email format</div>
                )}
              </div>
            </div>
            <div className="mb-4 position-relative">
              <input
                className="form-control lg"
                type="password"
                name="password"
                placeholder="Password"
                required=""
                value={user.password}
                onChange={handleInputChange}
              />
            </div>
            <div className="pb-3">
              <div className="d-flex flex-wrap align-items-center justify-content-between pb-4"></div>
            </div>

            <button
              onClick={handleRegister}
              className="btn btn-lg btn-primary w-100"
              type="submit"
            >
              Sign Up
            </button>
            {showAlert ? <Alert /> : <></>}
            <p className="pt-4 text-center">
              <span className="text-muted">Thank you for your trust</span>{" "}
            </p>
          </div>
        </div>
        <div
          className="w-50 bg-cover bg-repeat-0 bg-position-center"
          style={{ backgroundImage: "url(assets/img/blog-2.jpg)" }}
        />
      </div>
    </div>
  );
}
