import React, { useState } from "react";
import { Modal } from "antd";
import UserService from "../../Services/user.service";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function ChangePassword() {
  document.title = "Reset password";

  const [user, setUser] = useState({ newPassword: "" });

  const currentUser = JSON.parse(localStorage.getItem("user"));

  const [alert, setAlert] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value || null });
  };
  const navigate = useNavigate();

  const Alert = () => {
    return (
      <div
        style={{ marginTop: "15px" }}
        class="alert alert-danger"
        role="alert"
      >
        Reset password failed. Please contact the administration.
      </div>
    );
  };

  const handleChangePassword = () => {
    UserService.ResetPassword(
      currentUser.token,
      currentUser.user.id,
      user.newPassword
    )
      .then((response) => {
        Modal.success({
          content: "Your password has been reset",
          onOk: () => {
            navigate("/");
          },
        });
      })
      .catch((error) => {
        setAlert(true);
      });
  };

  return (
    <div id="main-wrapper">
      <Link
        className="circle bg-white text-info border square--40 position-absolute top-0 end-0 mt-3 me-3 z-2"
        to="/"
      >
        <i className="fa-solid fa-house-user" />
      </Link>
      <section className="position-relative">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6 com-md-9">
              {/* Signup Form */}
              <div className="position-relative">
                <div className="card border-0 rounded-5 p-xl-4 p-lg-4 p-3">
                  <div className="square--80 circle bg-light-primary text-primary d-flex mb-4 mx-auto">
                    <i className="fa-solid fa-key fs-1" />
                  </div>
                  <div className="card-wrap text-center mb-4">
                    <h1 className="fs-2">Set New Password</h1>
                    <p>
                      Your new password must be different to previously used
                      password.
                    </p>
                  </div>
                  <div className="form-floating position-relative mb-4">
                    <i className="fa-solid fa-lock position-absolute top-50 start-0 translate-middle-y ms-3 text-info" />
                    <input
                      type="password"
                      className="form-control form-control-lg ps-5"
                      id="password-field"
                      name="newPassword"
                      value={user.newPassword}
                      onChange={handleInputChange}
                      placeholder="Password"
                    />
                    <label className="ms-4">New Password</label>
                    <span className="fa-solid fa-eye toggle-password position-absolute top-50 end-0 translate-middle-y me-3 text-info opacity-75" />
                  </div>
                  {user?.newPassword?.length < 5 ||
                  user.newPassword === null ||
                  user.newPassword === "" ||
                  user?.newPassword?.length === 0 ? (
                    <button
                      onClick={handleChangePassword}
                      className="btn btn-lg btn-primary w-100"
                      type="submit"
                      disabled
                    >
                      Password lenght shoud be at least more that 5 alphabets.
                    </button>
                  ) : (
                    <button
                      onClick={handleChangePassword}
                      className="btn btn-lg btn-info w-100"
                      type="submit"
                    >
                      Reset Password
                    </button>
                  )}
                  {alert ? <Alert /> : <></>}{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
