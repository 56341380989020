import React, { Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link,
  NavLink,
} from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Home from './Collaboratives/Home/index';
import Register from './Collaboratives/Register';
import ChangePassword from './Collaboratives/ChangePassword';
import DashboardTrainings from './Admin/Trainings/index';
import Preloader from './Collaboratives/Home/Preloader';
const Explore = lazy(() => import('./Collaboratives/Explore'));
const Orders = lazy(() => import('./Collaboratives/Orders'));
const TrainingDetail = lazy(() => import('./Collaboratives/TrainingDetail'));
const Users = lazy(() => import('./Admin/Users'));
const Playlists = lazy(() => import('./Admin/Trainings/Playlists'));
const Subscriptions = lazy(() => import('./Admin/Subscriptions/index'));
const Analyses = lazy(() => import('./Admin/Analyses/index'));

function App() {
  const currentUser = JSON.parse(localStorage.getItem('user'));

  const Logout = () => {
    localStorage.removeItem('user');
    localStorage.clear();
    window.location.reload(false);
  };
  const NavBar = () => {
    const location = useLocation();
    let className = 'header header-transparent change-logo';
    let color = 'white';

    if (
      location.pathname === '/Trainings' ||
      location.pathname === '/TrainingDetail' ||
      location.pathname === '/Explore' ||
      location.pathname === '/Orders' ||
      location.pathname === '/TrainingDashboard' ||
      location.pathname === '/Users' ||
      location.pathname === '/Subscriptions' ||
      location.pathname === '/Playlist' ||
      location.pathname === '/Analyses'
    ) {
      className = 'header header-light';
      color = 'black';
    }

    if (
      location.pathname === '/Register' ||
      location.pathname === '/register' ||
      location.pathname === '/ChangePassword'
    ) {
      return null;
    }
    const handleNavLinkClick = (event) => {
      event.preventDefault();
    };
    return (
      <div
        className={className}
        style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)' }}
      >
        <div className="container">
          <nav id="navigation" className="navigation navigation-landscape">
            <div className="nav-header">
              <NavLink className="nav-brand static-logo" to="/Home">
                <img src="assets/img/logo-light copy.png" alt="" />
              </NavLink>
              <NavLink className="nav-brand fixed-logo" to="/Home">
                <img src="assets/img/logo-2.png" alt="" />
              </NavLink>
              <div className="nav-toggle" />
              <div className="mobile_nav">
                <ul>
                  {currentUser === null ? (
                    <li>
                      <a
                        href="JavaScript:Void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#login"
                      >
                        <i className="fas fa-sign-in-alt me-2" />
                        Sign In
                      </a>
                    </li>
                  ) : (
                    <li>
                      <div class="btn-group account-drop">
                        <a
                          href="JavaScript:Void(0);"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          style={{ color: 'black' }}
                        >
                          {currentUser?.user?.login}
                          <i
                            style={{ marginLeft: '15px' }}
                            className="fas fa-user me-2"
                          />
                        </a>

                        <div class="dropdown-menu pull-right animated flipInX">
                          <div class="drp_menu_headr">
                            <h4>Hi {currentUser?.user?.login}</h4>

                            <div class="drp_menu_headr-right">
                              <button
                                onClick={Logout}
                                type="button"
                                class="btn btn-whites"
                              >
                                Logout
                              </button>
                            </div>
                          </div>
                          <ul>
                            {currentUser.user.role == 'admin' ? (
                              <></>
                            ) : (
                              <>
                                {' '}
                                <li class="py-3">
                                  <NavLink
                                    to="/Orders"
                                    class="fw-medium active text-primary"
                                  >
                                    <i class="fa-solid fa-basket-shopping me-2"></i>
                                    My Orders
                                  </NavLink>
                                </li>
                              </>
                            )}
                            <li class="py-1">
                              <NavLink
                                to="/ChangePassword"
                                class="fw-medium active text-primary"
                              >
                                <i class="fa-solid fa-lock me-2"></i>
                                Change password
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div
              className="nav-menus-wrapper"
              style={{ transitionProperty: 'none' }}
            >
              <ul className="nav-menu">
                {currentUser == null || currentUser?.user?.role == 'trainee' ? (
                  <li>
                    <NavLink to="/Explore">Explore</NavLink>
                  </li>
                ) : (
                  <></>
                )}

                {currentUser?.user?.role == 'trainee' ? (
                  <li>
                    <NavLink to="/Orders">My learnings</NavLink>
                  </li>
                ) : (
                  <></>
                )}
                {currentUser?.user?.role == 'admin' ? (
                  <>
                    <li>
                      <NavLink to="/Users">Users</NavLink>
                    </li>
                    <li>
                      <NavLink to="/Subscriptions">Susbcriptions</NavLink>
                    </li>
                    <li>
                      <NavLink to="/Analyses">Analyses</NavLink>
                    </li>
                  </>
                ) : (
                  <></>
                )}
              </ul>
              <ul className="nav-menu nav-menu-social align-to-right">
                {currentUser === null ? (
                  <li>
                    <NavLink
                      href="JavaScript:Void(0);"
                      data-bs-toggle="modal"
                      data-bs-target="#login"
                    >
                      <i className="fas fa-sign-in-alt me-2" />
                      Sign In
                    </NavLink>
                  </li>
                ) : (
                  <li>
                    <div class="btn-group account-drop">
                      <a
                        href="JavaScript:Void(0);"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{
                          color: color,
                          fontWeight: '500',
                          marginTop: '9px',
                        }}
                      >
                        {currentUser?.user?.login}
                        <i
                          style={{ marginLeft: '15px' }}
                          className="fas fa-user me-2"
                        />
                      </a>

                      <div class="dropdown-menu pull-right animated flipInX">
                        <div class="drp_menu_headr">
                          <h4>Hi {currentUser?.user?.login.toUpperCase()}</h4>

                          <div class="drp_menu_headr-right">
                            <button
                              onClick={Logout}
                              type="button"
                              class="btn btn-whites"
                            >
                              Logout
                            </button>
                          </div>
                        </div>
                        <ul>
                          {currentUser.user.role == 'admin' ? (
                            <></>
                          ) : (
                            <>
                              {' '}
                              <li class="py-3">
                                <NavLink
                                  to="/Orders"
                                  class="fw-medium active text-primary"
                                >
                                  <i class="fa-solid fa-basket-shopping me-2"></i>
                                  My Orders
                                </NavLink>
                              </li>
                            </>
                          )}
                          <li class="py-1">
                            <NavLink
                              to="/ChangePassword"
                              class="fw-medium active text-primary"
                            >
                              <i class="fa-solid fa-lock me-2"></i>
                              Change password
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </nav>
        </div>
      </div>
    );
  };
  const Footer = () => {
    const location = useLocation();

    if (
      location.pathname === '/Register' ||
      location.pathname === '/ChangePassword' ||
      location.pathname === '/' ||
      currentUser?.user?.role == 'admin' ||
      location.pathname === '/Playlist' ||
      Location.pathname === '/Subscriptions'
    ) {
      return null;
    }
    return (
      <>
        <div>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-4">
                <div className="footer-widget">
                  <img
                    src="assets/img/logo-2.png"
                    className="img-footer"
                    alt=""
                  />
                  <div className="footer-add">
                    <p>
                      Algiers
                      <br />
                      Algeria.
                    </p>
                  </div>
                  <div className="foot-socials">
                    <ul>
                      <li>
                        <a href="JavaScript:Void(0);">
                          <i className="fa-brands fa-facebook" />
                        </a>
                      </li>
                      <li>
                        <a href="JavaScript:Void(0);">
                          <i className="fa-brands fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a href="JavaScript:Void(0);">
                          <i className="fa-brands fa-google-plus" />
                        </a>
                      </li>
                      <li>
                        <a href="JavaScript:Void(0);">
                          <i className="fa-brands fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="JavaScript:Void(0);">
                          <i className="fa-brands fa-dribbble" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4">
                <div className="footer-widget">
                  <h4 className="widget-title">The Navigation</h4>
                  <ul className="footer-menu">
                    <li>
                      <NavLink to="/Explore">Explore</NavLink>
                    </li>
                    {currentUser == null ? (
                      <></>
                    ) : (
                      <li>
                        <NavLink to="/Orders">Orders</NavLink>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              {/* <div className="col-lg-3 col-md-6">
                <div className="footer-widget">
                  <h4 className="widget-title">Download Apps</h4>
                  <div className="app-wrap">
                    <p>
                      <a href="JavaScript:Void(0);">
                        <img
                          src="assets/img/google-app.png"
                          className="img-fluid"
                          alt=""
                        />
                      </a>
                    </p>
                    <p>
                      <a href="JavaScript:Void(0);">
                        <img
                          src="assets/img/ios-app.png"
                          className="img-fluid"
                          alt=""
                        />
                      </a>
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-4 col-lg-5 col-md-5">
                <p className="mb-0">© 2024</p>
              </div>
              {/* <div className="col-xl-8 col-lg-7 col-md-7">
                <div className="job-info-count-group">
                  <div className="single-jb-info-count">
                    <div className="jbs-y7">
                      <h5 className="ctr">12</h5>
                      <span className="theme-2-cl">K</span>
                    </div>
                    <div className="jbs-y5">
                      <p>Active users</p>
                    </div>
                  </div>
                  <div className="single-jb-info-count">
                    <div className="jbs-y7">
                      <h5 className="ctr">10</h5>
                      <span className="theme-2-cl">M</span>
                    </div>
                    <div className="jbs-y5">
                      <p>Happy Customers</p>
                    </div>
                  </div>
                  <div className="single-jb-info-count">
                    <div className="jbs-y7">
                      <h5 className="ctr">76</h5>
                      <span className="theme-2-cl">K</span>
                    </div>
                    <div className="jbs-y5">
                      <p>Followers</p>
                    </div>
                  </div>
                  <div className="single-jb-info-count">
                    <div className="jbs-y7">
                      <h5 className="ctr">200</h5>
                      <span className="theme-2-cl">+</span>
                    </div>
                    <div className="jbs-y5">
                      <p>Companies</p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div>
        <Router>
          <NavBar />
          {currentUser === null ? (
            <Routes>
              <Route exact path="/" element={<Preloader />} />
              <Route exact path="/Home" element={<Home />} />
              <Route
                exact
                path="/Explore"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <Explore />
                  </Suspense>
                }
              />
              <Route exact path="/Register" element={<Register />} />
              <Route
                exact
                path="/TrainingDetail"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <TrainingDetail />
                  </Suspense>
                }
              />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          ) : currentUser?.user?.role === 'admin' ? (
            <Routes>
              <Route
                exact
                path="/TrainingDashboard"
                element={<DashboardTrainings />}
              />
              <Route
                exact
                path="/Playlist"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <Playlists />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/Subscriptions"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <Subscriptions />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/Users"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <Users />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/Analyses"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <Analyses />
                  </Suspense>
                }
              />
              <Route path="*" element={<Navigate to="/TrainingDashboard" />} />
              <Route
                exact
                path="/ChangePassword"
                element={<ChangePassword />}
              />
            </Routes>
          ) : (
            <Routes>
              <Route exact path="/" element={<Preloader />} />
              <Route exact path="/Home" element={<Home />} />
              <Route
                exact
                path="/TrainingDetail"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <TrainingDetail />
                  </Suspense>
                }
              />

              <Route
                exact
                path="/Orders"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <Orders />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/ChangePassword"
                element={<ChangePassword />}
              />
              <Route
                exact
                path="/Explore"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <Explore />
                  </Suspense>
                }
              />
            </Routes>
          )}
          <Footer />
        </Router>
      </div>
    </>
  );
}

export default App;
