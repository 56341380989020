import http from "../http-common";

class UserService {
  Login = async (email, password) => {
    const response = await http.post("/signin", { email, password });
    localStorage.setItem("user", JSON.stringify(response.data));
  };
  Register(email, password, login) {
    return http.post("/signup-trainee", { email, password, login });
  }
  ResetPassword(token, userId, newPassword) {
    const config = {
      headers: {
        Authorization: `${token}`,
      },
    };

    const data = {
      newPassword,
    };

    return http.post(`/resetPassword/${userId}`, data, config);
  }
  GetAllUsers(token) {
    const config = {
      headers: {
        Authorization: `${token}`,
      },
    };

    return http.get(`/getAllUsers`, config);
  }
  DeleteUser(id, token) {
    const config = {
      headers: {
        Authorization: `${token}`,
      },
    };

    return http.delete(`/deleteUser/${id}`, config);
  }
}
export default new UserService();
