// Preloader.js

import React, { useState, useEffect } from "react";
import "./Preloader.css";
import { useNavigate } from "react-router-dom";
const Preloader = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const delay = setTimeout(() => {
      setLoading(false);
      navigate("/Home");
    }, 5000);
    return () => clearTimeout(delay);
  }, [navigate]);

  return (
    <div
      className={`preloader-container text-center ${
        loading ? "visible" : "invisible"
      }`}
    >
      <img
        src="assets/img/logo-light copy.png"
        alt="Logo"
        className={`logo ${loading ? "fade-in-out" : ""}`}
      />
    </div>
  );
};

export default Preloader;
